var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',{attrs:{"color":"grey lighten-5","elevation":"0"}},[_c('v-card-title',[_c('h3',{staticClass:"font-weight-black display-0 basil--text"},[_vm._v(" MERMAS ")])])],1),_c('br'),_c('v-dialog',{attrs:{"max-width":"480","persistent":""},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("¿Esta seguro de eliminar este registro?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","xs":"12","md":"2"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-7",attrs:{"label":"Buscar por fecha","persistent-hint":"","append-icon":"mdi-calendar","outlined":"","dense":""},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.dateFormatted)}},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"green lighten-1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","xs":"12","md":"3"}},[_c('v-select',{staticClass:"mt-7",attrs:{"items":_vm.listReason,"item-text":"name","item-value":"id","clearable":"","outlined":"","dense":"","label":"Motivo de merma"},on:{"input":_vm.filterReason},model:{value:(_vm.wastage_reason_id),callback:function ($$v) {_vm.wastage_reason_id=$$v},expression:"wastage_reason_id"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.wastages,"footer-props":{ 'items-per-page-options': [5, 10, 20, 50] },"server-items-length":_vm.totalWastages,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},(_vm.progress)?{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":40,"width":3,"color":"green","indeterminate":""}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"text-center ma-2"},[_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }