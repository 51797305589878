<template>
  <div>
    <v-container>
      <v-card color="grey lighten-5" elevation="0">
        <v-card-title>
          <h3 class="font-weight-black display-0 basil--text">
            MERMAS
          </h3>
        </v-card-title>
      </v-card><br>
      <v-dialog
        v-model="dialogDelete"
        max-width="480"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5">¿Esta seguro de eliminar este registro?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="2"
        >
          <v-menu
            ref="menu1"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Buscar por fecha"
                persistent-hint
                append-icon="mdi-calendar"
                v-bind="attrs"
                outlined
                dense
                @blur="date = parseDate(dateFormatted)"
                v-on="on"
                class="mt-7"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="menu = false"
              color="green lighten-1"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="3"
        >
          <v-select
            v-model="wastage_reason_id"
            :items="listReason"
            item-text="name"
            item-value="id"
            clearable
            outlined
            dense
            label="Motivo de merma"
            class="mt-7"
            @input="filterReason"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="wastages"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
        :server-items-length="totalWastages"
        :options.sync="options"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-if="progress" v-slot:no-data>
          <div class="text-center">
            <v-progress-circular
              :size="40"
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbar"
        >
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </div>
</template>

<script>
import {Errors} from "../../plugins/errors";
import axios from "axios";
import {mapState} from "vuex"
import moment from 'moment'

export default {
  name: "Wastage",
  components: {},
  data() {
    return {
      dialogDelete: false,
      progress: false,
      deletedItem: {},
      wastages: [],
      totalWastages: 0,
      listReason: [],
      wastage_reason_id: '',
      errors: new Errors(),
      snackbar: false,
      menu: false,
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      date: moment(String(new Date())).format('YYYY-MM-DD hh:mm:ss'),
      filters: {
        search: ''
      },
      text: 'Operación realizada con éxito !!!',
      options: {},
      headers: [
        { text: 'FACTURA/BOLETA', value: 'details.invoice.number',        sortable: false, class: "success white--text"},
        { text: 'PRODUCTO',       value: 'product.name',                  sortable: false, class: "success white--text"},
        { text: 'MOTIVO',         value: 'wastage.reason.name',           sortable: false, class: "success white--text"},
        { text: 'FECHA',          value: 'wastage.date',                  sortable: false, class: "success white--text"},
        { text: 'MONTO',          value: 'cost_total',                    sortable: false, class: "success white--text"},
        { text: 'RESPONSABLE',    value: 'wastage.responsable.full_name', sortable: false, class: "success white--text"},
        { text: 'ACCIONES',       value: 'actions',                       sortable: false, class: "success white--text"},
      ],
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  watch: {
    dialogDelete (val) {
      val || this.closeDelete()
    },
    date () {
      this.dateFormatted = this.formatDate(this.date)
      this.filterDate()
    },
    opening () {
      this.dateFormatted = this.formatDate(this.opening_date)
    },
    options: {
      async handler () {
        await this.fetchWastages();
      }
    },
  },
  created () {
    this.fetchWastages()
    this.fetchReason()
  },
  methods: {
    fetchReason () {
      axios.get('/api/wastage/reason')
        .then(response => this.listReason = response.data.data)
        .catch(error => console.log(error))
    },
    fetchWastages () {
      const params = { ...this.options, ...this.filters }
      this.progress = true
      axios.get('/api/wastage', {params})
        .then(response => {
          this.wastages = response.data.data.data
          this.totalWastages = response.data.data.total
          this.progress = false
        })
    },
    deleteItem (item) {
      this.deletedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteWastages () {
      let deleteWastage = {
        'wastage' : this.deletedItem,
        'area_assignment_id': this.user[0].id,
      }
      axios.post('/api/wastage/reverse', deleteWastage)
        .then(() => {
          this.fetchWastages()
          this.snackbar = true;
        })
    },
    closeDelete () {
      this.dialogDelete = false
      this.deletedItem = {}
    },
    deleteItemConfirm () {
      this.deleteWastages()
      this.dialogDelete = false
      this.deletedItem = {}
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    filterDate () {
      const params = {
        'date': this.date
      }
      this.progress = true
      axios.get('/api/wastage/search/date', {params})
        .then(response => {
          this.wastages = response.data.data.data
          this.totalWastages = response.data.data.total
          this.progress = false
        })
    },
    filterReason () {
      const params = {
        'wastage_reason_id': this.wastage_reason_id
      }
      this.progress = true
      axios.get('/api/wastage/search/reason', {params})
        .then(response => {
          this.wastages = response.data.data.data
          this.totalWastages = response.data.data.total
          this.progress = false
        })
    }
  }
}
</script>
<style>
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
